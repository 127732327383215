import React, { createContext, useState } from "react";

export const HeaderDataContext = createContext({
	heading: "",
	setHeading: () => {},
	selectedContact: null,
	setSelectedContact: () => {},
});

export default function HeaderContext({ children }) {
	const [heading, setHeading] = useState("Dashboard");
	const [selectedContact, setSelectedContact] = useState(null);

	return (
		<HeaderDataContext.Provider
			value={{
				heading,
				setHeading,
				selectedContact,
				setSelectedContact,
			}}
		>
			{children}
		</HeaderDataContext.Provider>
	);
}
