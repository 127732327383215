import React, { useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBookOfSeries } from "../redux/Slice/SeriesSlice";
import { useDispatch, useSelector } from "react-redux";
import ListCard from "./ListCard";
import FullPageSpinner from "./loader/FullPageSpinner";
import { HeaderDataContext } from "../context/HeaderContext";

const BookOfSeriesCards = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { booksOfSeries, loading } = useSelector((state) => state.series);
	const pathArr = useLocation().pathname.split("/");

	let pathName = "";

	if (pathArr[2] === "out-line") {
		pathName = "out-line/list";
	}
	if (pathArr[2] === "plot-line") {
		pathName = "plot-line/list";
	}
	if (pathArr[2] === "time-line") {
		pathName = "time-line/list";
	}
	if (pathArr[2] === "brain-storm") {
		pathName = "brain-storm/list";
	}
	useEffect(() => {
		dispatch(getBookOfSeries(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="main-container">
			<div className="card-container mt-2">
				<div className="p-3">
					{booksOfSeries[0]?.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>This Series has no books</h4>
					) : (
						booksOfSeries?.length > 0 &&
						booksOfSeries[0]?.map((book) => {
							return loading ? (
								<FullPageSpinner />
							) : (
								<ListCard
									style={{
										backgroundColor: book.color_code,
										color: book.foreground_color,
										cursor: "pointer",
									}}
									foreground_color={book.foreground_color}
									color_code={book.color_code}
									id={book.id}
									name={book.book_name}
									description={book.book_description}
									pathname={pathName}
									icon={book.image_url}
									type="series"
								/>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
};

export const BookOfSeriesDashboard = () => {
	const header = useContext(HeaderDataContext);
	const { id } = useParams();
	const dispatch = useDispatch();
	const { booksOfSeries, loading } = useSelector((state) => state.series);

	useEffect(() => {
		header.setHeading("Books of Series");
		if (booksOfSeries[0] && booksOfSeries[0].length > 0) {
			header.setHeading(`Books of ${booksOfSeries[0][0].series_name} Series`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [booksOfSeries]);

	useEffect(() => {
		dispatch(getBookOfSeries(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="main-container">
			<div className="card-container mt-2">
				<div className="p-3">
					{loading ? (
						<FullPageSpinner />
					) : (
						<>
							{booksOfSeries[0]?.length === 0 ? (
								<h4 style={{ textAlign: "center" }}>
									This Series has no books
								</h4>
							) : (
								booksOfSeries?.length > 0 &&
								booksOfSeries[0]?.map((book) => (
									<ListCard
										key={book.id}
										style={{
											backgroundColor: book.color_code,
											color: book.foreground_color,
										}}
										foreground_color={book.foreground_color}
										color_code={book.color_code}
										id={book.id}
										name={book.book_name}
										description={book.book_description}
										icon={book.image_url}
										type="book"
									/>
								))
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default BookOfSeriesCards;
