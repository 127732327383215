import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
// import * as firebase from 'firebase/app';
import store from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistore = persistStore(store);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistore}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
