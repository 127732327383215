import React, { useEffect, useState } from "react";
import { Col, Divider, Menu, Modal, Row, Upload, notification } from "antd";
import "./gallery.css";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";

const UserGallery = ({ setShowGallery, setSelectedFile }) => {
	const token = localStorage.getItem("token");
	const [notifyApi, contextHolder] = notification.useNotification();
	const [gallery, setGallery] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);

	useEffect(() => {
		fetchUserGallery(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchUserGallery = (page) => {
		axios(`${process.env.REACT_APP_API_URL}/user-galleries?page=${page}`, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				setGallery(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};

	const handleChangeImage = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const deleteGallery = (id) => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/user-galleries/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				if (response.data.success) {
					notifyApi.success({
						message: "Success",
						description: "Image has been deleted.",
					});
				}
				fetchUserGallery(1);
			})
			.catch((error) => {
				if (
					error.response?.status === 403 ||
					error.response?.status === 500 ||
					error.response?.status === 404
				) {
					notifyApi.error({
						message: "Error!",
						description:
							"Something went wrong, either you are not authorized to delete or the image could not be deleted.",
					});
				}
			});
	};
	const uploadImage = async () => {
		const form = new FormData();
		form.append("image", fileList[0].originFileObj);

		const { data } = await axios.post(
			`${process.env.REACT_APP_API_URL}/user-galleries`,
			form,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setSelectedFile(data?.data);
		fetchUserGallery(1);
	};

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	return (
		<>
			{contextHolder}
			<Menu className="Menu">
				<p className="change_book mt-3">Change Artwork</p>
				<Menu.Item className="artwork-menu-item">
					<Row
						style={{
							flexDirection: "row",
							justifyContent: "flex-start",
							marginTop: 10,
						}}
					>
						{gallery.length > 0 &&
							gallery.map((g) => {
								return (
									<Col key={g.id} className="col-gallery">
										<div className="gallery-item">
											<button
												type="button"
												className="btn-delete-image"
												onClick={() => deleteGallery(g.id)}
											>
												X
											</button>
											<img
												src={g.image_url ?? null}
												alt="Artwork"
												onClick={() => {
													setSelectedFile(g);
													setShowGallery(false);
												}}
											/>
										</div>
									</Col>
								);
							})}
					</Row>
					<Divider />
					<Upload
						accept=".png"
						listType="picture-card"
						fileList={fileList}
						customRequest={dummyRequest}
						onPreview={handlePreview}
						onChange={handleChangeImage}
						beforeUpload={(file) => {
							const isImage =
								file.type === "image/jpeg" ||
								file.type === "image/png" ||
								file.type === "image/gif";
							if (!isImage) {
								console.error("You can only upload JPG, PNG, or GIF file.");
								return false;
							} else {
								return true;
							}
						}}
					>
						{fileList.length >= 1 ? null : (
							<div>
								<PlusOutlined />
								<div
									style={{
										marginTop: 8,
										fontSize: "12px",
									}}
								>
									Upload your own
								</div>
							</div>
						)}
					</Upload>
					<Modal
						open={previewOpen}
						title={previewTitle}
						footer={null}
						onCancel={handleCancel}
					>
						<img alt="example" style={{ width: "100%" }} src={previewImage} />
					</Modal>
					<Divider />
					<div className="upload_btns">
						<button type="button" onClick={() => setShowGallery(false)}>
							Cancel
						</button>
						<button onClick={() => uploadImage()} type="button">
							Upload
						</button>
					</div>
				</Menu.Item>
			</Menu>
		</>
	);
};

export default UserGallery;
