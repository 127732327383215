import React, { useEffect, useState } from "react";

import { Drawer, Button, message } from "antd";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import newBook from "../../assets/images/newBook.png";
import { getColorData } from "../../redux/Slice/ColorSlice";
import UserGallery from "../gallery/UserGallery";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import axios from "axios";

const EditTimelineDrawer = ({
	timeline,
	open,
	close,
	books,
	getTimelinesByBook,
}) => {
	const selectColor = {
		value: "",
		label: "-- Select Color --",
	};
	const selectBook = {
		value: "",
		label: "-- Select Book --",
	};

	const [timelineName, setTimelineName] = useState("");
	const [timelineDescription, setTimelineDescription] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [showGallery, setShowGallery] = useState(false);
	const [loading, setLoading] = useState(false);
	const { colors } = useSelector((state) => state.colors);

	const [colorOptions, setColorOptions] = useState([]);
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedBook, setSelectedBook] = useState(null);

	const [error, setError] = useState({});

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getColorData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (timeline) {
			console.log(timeline);
			setTimelineName(timeline.name);
			setTimelineDescription(timeline.description);
			setSelectedFile({
				id: timeline.image_id,
				image_url: timeline.image_url,
			});
			setSelectedColor({
				value: timeline.color_id,
				label: timeline.color,
			});
			setSelectedBook({
				value: timeline.book.book_id,
				label: timeline.book.book_name,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeline]);

	useEffect(() => {
		const options = colors.map((color) => {
			return {
				value: color.id,
				label: color.color,
			};
		});

		setColorOptions(() => [selectColor, ...options]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colors]);

	const updateTimeline = (e) => {
		const token = localStorage.getItem("token");
		const timelineData = {
			book_id: selectedBook.value,
			name: timelineName,
			description: timelineDescription,
			color_id: selectedColor.value,
			image_id: selectedFile?.id ?? null,
		};

		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/timelines/${timeline.id}`,
				timelineData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				}
			)
			.then(() => {
				getTimelinesByBook(selectedBook);
				setSelectedBook(null);
				setSelectedColor(null);
				setSelectedFile(null);
				setTimelineName("");
				setTimelineDescription("");
				setError({});
				setLoading(false);
				close();
			})
			.catch((error) => {
				if (error.response.status === 422) {
					setError(error.response.data.errors);
				} else if (error.response.status === 403) {
					message.error(error.response.data.message);
				} else {
					message.error("Something went wrong!");
				}
				setLoading(false);
			});
	};

	return (
		timeline && (
			<Drawer
				title={"Edit Timeline"}
				placement="right"
				onClose={close}
				open={open}
				className="p-1 drawer"
				zIndex={0}
				autoFocus={false}
				mask={false}
			>
				<form>
					<label>Artwork</label>
					{showGallery && (
						<UserGallery
							setSelectedFile={(g) => {
								setSelectedFile(g);
							}}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={() => {
										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
					<div className="mt-3">
						<div className="form-input">
							<label>
								Select Book: <span className="required">*</span>
							</label>
							<Select
								options={[selectBook, ...books]}
								onChange={(e) => {
									setSelectedBook(e);
								}}
								isSearchable={true}
								className="select-book"
								value={selectedBook}
							/>
							{error.book_id && <span className="error">{error.book_id}</span>}
						</div>
					</div>
					<div className="mt-3">
						<TextInput
							id="name"
							name="name"
							label="Name"
							maxLength={50}
							required
							value={timelineName}
							onChange={(e) => setTimelineName(e.target.value)}
							error={error.name}
						/>
					</div>
					<div className="mt-3">
						<TextAreaInput
							id="description"
							name="description"
							label="Description"
							maxLength={2048}
							showCount={true}
							value={timelineDescription}
							error={error.description}
							onChange={(e) => setTimelineDescription(e.target.value)}
						/>
					</div>
					<div className="mt-3">
						<div className="form-input">
							<label>Select Color: </label>
							<Select
								options={colorOptions}
								onChange={(e) => {
									setSelectedColor(e);
								}}
								isSearchable={true}
								className="select-book"
								value={selectedColor}
							/>
							{error.color_id && (
								<span className="error">{error.color_id}</span>
							)}
						</div>
					</div>

					<div className="buttons-container mt-3 d-flex space-between">
						<Button
							type="primary"
							className="mr-2"
							loading={loading}
							disabled={loading}
							onClick={updateTimeline}
						>
							Save
						</Button>
						<Button type="ghost" onClick={() => close()}>
							Cancel
						</Button>
					</div>
				</form>
			</Drawer>
		)
	);
};

export default EditTimelineDrawer;
