import React, { useState, useEffect, useContext } from "react";
import CustomButton from "../button/Button";
import Select from "react-select";
import CreateBookIcon from "../../assets/icons/create-book.svg";
import { useParams } from "react-router-dom";

import { getBooks, getBrainstorms } from "../../helpers/GetList";

// import FullPageSpinner from "../loader/FullPageSpinner";
import BrainstormCard from "./BrainstormCard";
import { Pagination, message } from "antd";
import { HeaderDataContext } from "../../context/HeaderContext";
import AddBrainstormDrawer from "./AddBrainstormDrawer";
import axios from "axios";
import EditBrainstormDrawer from "./EditBrainstormDrawer";

const Brainstorms = () => {
	const token = localStorage.getItem("token");
	const header = useContext(HeaderDataContext);
	header.setHeading("Brainstorms");
	const selectBook = {
		value: "",
		label: "-- Any Book --",
	};

	const { id } = useParams();

	const [books, setBooks] = useState([]);
	const [brainstorms, setBrainstorms] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedBrainstorm, setSelectedBrainstorm] = useState(null);

	const [selectedBook, setSelectedBook] = useState(selectBook);
	const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);

	useEffect(() => {
		getBooks(id, setSelectedBook, setBooks);
		getBrainstorms(id, 1, setBrainstorms, setTotalRecords);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBrainstormsByBook = (book) => {
		setSelectedBook(book);
		getBrainstorms(book.value, 1, setBrainstorms, setTotalRecords);
		setCurrentPage(1);
	};

	const handlePageClick = (page) => {
		setCurrentPage(page);
		getBrainstorms(selectedBook?.value, page, setBrainstorms, setTotalRecords);
	};

	const editBrainstorm = (id) => {
		axios(`${process.env.REACT_APP_API_URL}/brainstorms/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		})
			.then((response) => {
				setSelectedBrainstorm(response.data.data);
				setShowEditDrawer(true);
			})
			.catch(() => message.error("Could not fetch Brainstorm"));
	};

	const deleteBrainstorm = (id) => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/outlines/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then(() => {
				getBrainstorms(selectedBook?.value, 1, setBrainstorms, setTotalRecords);
				setCurrentPage(1);
			})
			.catch(() => message.error("Could not delete Brainstorm."));
	};

	return (
		<div className="main-container">
			<div className="list-actions">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle="Add Brainstorm"
						onClick={() => setShowAddNewDrawer(true)}
						icon={<img src={CreateBookIcon} alt="New Brainstorm" />}
					/>
				</div>

				{books.length > 0 && (
					<div className="filter">
						<label>Select book:</label>
						<Select
							options={[selectBook, ...books]}
							onChange={getBrainstormsByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
				)}
			</div>
			<div className="card-container mt-2">
				<div className="p-3">
					{brainstorms.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No brainstorm to display, click on Add Brainstorm to add new.
						</h4>
					) : (
						brainstorms.map((brainstorm) => {
							return (
								<BrainstormCard
									key={brainstorm.id}
									brainstorm={brainstorm}
									style={{
										backgroundColor: brainstorm.color_code,
										color: brainstorm.foreground_color,
										cursor: "pointer",
									}}
									editBrainstorm={editBrainstorm}
									deleteBrainstorm={deleteBrainstorm}
								/>
							);
						})
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>
			<AddBrainstormDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getBrainstormsByBook={getBrainstormsByBook}
			/>
			<EditBrainstormDrawer
				brainstorm={selectedBrainstorm}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getBrainstormsByBook={getBrainstormsByBook}
			/>
		</div>
	);
};

export default Brainstorms;
