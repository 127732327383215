import React, { useEffect, useState, useRef } from "react";

import { axiosInstance } from "../../services/apiServices/config";
import { Row, Col, Card, notification /*Space, Button*/ } from "antd";
import BSRounds from "./BSRounds";
// import BrainStormScale from "../../components/brainstorm/BrainStormScale";
// import Menu from "../../assets/icons/menu.svg";
// import BookNameOutline from "../../components/cards/outline-cards/book-name-outline/BookNameOutlineCard";

import { useParams } from "react-router-dom";
import { useAudioRecorder } from "react-audio-voice-recorder";
import SpeechRecognition, {
	useSpeechRecognition,
} from "react-speech-recognition";

import { blobToBase64, dataURLtoFile } from "../../helpers";

import FullPageSpinner from "../loader/FullPageSpinner";
import "./recorder.css";

import BookDescription from "./BookDescription";
import RoundPlayer from "./RoundPlayer";
import Recorder from "./Recorder";
import axios from "axios";

const BrainstormDetails = () => {
	const [notifyApi, contextHolder] = notification.useNotification();

	const recorder = useAudioRecorder();
	const refRoundTitle = useRef();
	const roundTranscript = useRef();
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [disableInput, setDisableInput] = useState(false);
	const [roundLoading, setRoundLoading] = useState(false);
	const [error, setError] = useState({});
	const [isError, setIsError] = useState(true);
	const [errorMessage, setErrorMessage] = useState(true);
	const [brainstorm, setBrainstorm] = useState(null);
	const [recordBlob, setRecordBlob] = useState(null);
	const [bsTranscript, setBSTranscript] = useState(null);
	const [addNew, setAddNew] = useState(false);
	const [roundIndex, setRoundIndex] = useState(0);
	const {
		transcript,
		// listening,
		resetTranscript,
		// browserSupportsSpeechRecognition,
	} = useSpeechRecognition();

	useEffect(() => {
		getBrainstorm(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!recorder.recordingBlob) return;
		setRecordBlob(recorder.recordingBlob);
		setDisableInput(false);
	}, [recorder.recordingBlob]);

	useEffect(() => {
		transcript && setBSTranscript(transcript);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transcript]);

	const getBrainstorm = async (id) => {
		try {
			const response = await axiosInstance.get(`/brainstorms/${id}`);
			setBrainstorm(response.data.data);
			setLoading(false);
			setIsError(false);
		} catch (error) {
			setIsError(true);
			setLoading(false);
			setErrorMessage(
				error.response?.status === 404
					? "Brainstorm not found"
					: "Something went wrong."
			);
		}
	};

	const onAddNew = () => {
		setRecordBlob(null);
		setAddNew(true);
	};

	const toggleRecording = () => {
		if (recorder.isRecording) {
			SpeechRecognition.stopListening();
			recorder.stopRecording();
			setBSTranscript(transcript);
		} else {
			recorder.startRecording();
			SpeechRecognition.startListening({
				continuous: true,
			});
			setDisableInput(true);
		}
	};

	const saveRound = () => {
		if (!recordBlob) {
			notifyApi.warning({
				message: "Warning!",
				description: "Please record audio.",
				placement: "top",
			});

			return false;
		}

		blobToBase64(recordBlob).then((base64) => {
			const recordedFile = dataURLtoFile(base64, "brainstorm.webm");
			const formData = new FormData();
			formData.append("brainstorm_id", brainstorm.id);
			formData.append("audio_file", recordedFile);
			formData.append("title", refRoundTitle.current.input.value);
			formData.append("transcript", bsTranscript);
			const token = localStorage.getItem("token");
			setRoundLoading(true);
			axios
				.post(`${process.env.REACT_APP_API_URL}/brainstorm-rounds`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				})
				.then(() => {
					setError({});
					refRoundTitle.current.input.value = "";
					setBSTranscript("");
					setRoundLoading(false);
					setAddNew(false);
					setRecordBlob(null);
					resetTranscript();
					notifyApi.success({
						message: "Success!",
						description: "Brainstorm round added successfully.",
						placement: "top",
					});
					getBrainstorm(id);
				})
				.catch((error) => {
					if (error.response?.data?.errors) {
						setError(error.response.data.errors);
					} else {
						notifyApi.error({
							message: "Error!",
							description: "Something went wrong.",
						});
					}
					setRoundLoading(false);
				});
		});
	};

	const deleteRound = async (id) => {
		try {
			await axiosInstance.delete(`/brainstorm-rounds/${id}`);
			notifyApi.success({
				message: "Success!",
				description: "Brainstorm Round deleted successfully.",
				placement: "top",
			});
			getBrainstorm(brainstorm.id);
			setRoundIndex(0);
		} catch (error) {
			notifyApi.warning({
				message: "Warning!",
				description: "Brainstorm Round could not be deleted.",
				placement: "top",
			});
		}
	};

	const selectRound = (index) => {
		setRoundIndex(index);
		setAddNew(false);
	};

	return loading ? (
		<FullPageSpinner />
	) : (
		<div className="main-container">
			{!isError ? (
				<>
					{contextHolder}
					<BookDescription
						name={brainstorm.brainstorm_name}
						description={brainstorm.description}
					/>
					<Card
						style={{
							borderRadius: "8px",
							boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
							padding: "12px",
						}}
						className="mt-5"
					>
						<Row>
							<Col sm={24} xs={24} md={24} lg={24} xxl={24}>
								{/* <BrainStormScale /> */}
								{addNew ? (
									<>
										<Recorder
											recorder={recorder}
											toggleRecording={toggleRecording}
											error={error}
										/>
										{/* <div id="recordedAudio"></div> */}
									</>
								) : (
									brainstorm.rounds.length > 0 && (
										<RoundPlayer
											rounds={brainstorm.rounds}
											roundIndex={roundIndex}
											selectRound={selectRound}
											deleteRound={deleteRound}
										/>
									)
								)}
							</Col>
						</Row>
						<BSRounds
							recordBlob={recordBlob}
							rounds={brainstorm.rounds}
							roundIndex={roundIndex}
							selectRound={selectRound}
							bsTranscript={bsTranscript}
							setBSTranscript={setBSTranscript}
							onAddNew={onAddNew}
							addNew={addNew}
							saveRound={saveRound}
							refRoundTitle={refRoundTitle}
							roundTranscript={roundTranscript}
							error={error}
							disableInput={disableInput}
							roundLoading={roundLoading}
							deleteRound={deleteRound}
						/>
					</Card>
				</>
			) : (
				<h1>{errorMessage}</h1>
			)}
		</div>
	);
};

export default BrainstormDetails;
