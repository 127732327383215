import React, { useContext, useEffect, useState } from "react";

import "../cards/index.scss";
import Select from "react-select";
import { useParams } from "react-router-dom";
import CustomButton from "../button/Button";
import CreateBookIcon from "../../assets/icons/create-book.svg";
import OutlineCard from "./OutlineCard";
import { getBooks, getOutlines } from "../../helpers/GetList";
// import PackageSubscriptionHook from "../PackageSubscriptionHook";
// import FullPageSpinner from "../loader/FullPageSpinner";
import { HeaderDataContext } from "../../context/HeaderContext";
import AddOutlineDrawer from "./AddOutlineDrawer";
import EditOutlineDrawer from "./EditOutlineDrawer";
import axios from "axios";
import { Pagination, message } from "antd";

const Outlines = () => {
	const token = localStorage.getItem("token");
	const header = useContext(HeaderDataContext);
	header.setHeading("Outlines");
	const { id } = useParams();

	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [outlines, setOutlines] = useState([]);
	const selectBook = {
		value: "",
		label: "-- Any Book --",
	};
	const [selectedBook, setSelectedBook] = useState(selectBook);

	const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [selectedOutline, setSelectedOutline] = useState(null);
	const [books, setBooks] = useState([]);

	useEffect(() => {
		getBooks(id, setSelectedBook, setBooks);
		getOutlines(id, 1, setOutlines, setTotalRecords);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getOutlineByBook = (book) => {
		setSelectedBook(book);
		getOutlines(book.value, 1, setOutlines, setTotalRecords);
		setCurrentPage(1);
	};

	const handlePageClick = (page) => {
		setCurrentPage(page);
		getOutlines(selectedBook?.value, page, setOutlines, setTotalRecords);
	};

	const editOutline = (id) => {
		axios(`${process.env.REACT_APP_API_URL}/outlines/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		})
			.then((response) => {
				setSelectedOutline(response.data.data);
				setShowEditDrawer(true);
			})
			.catch((error) => console.log(error));
	};

	const deleteOutline = (id) => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/outlines/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then(() => {
				getOutlines(selectedBook?.value, 1, setOutlines, setTotalRecords);
				setCurrentPage(1);
			})
			.catch(() => message.error("Could not delete timeline."));
	};

	return (
		<div className="main-container">
			{/* <PackageSubscriptionHook
				feature={"outline-feature"}
				packageName={"outline-feature"}
				ref={ref}
				title={"Outline"}
				checkPackageSubscription={checkSubscription}
			/> */}
			<div className="list-actions">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle="Add Outline"
						onClick={() => setShowAddNewDrawer(true)}
						icon={<img src={CreateBookIcon} alt="New Outline" />}
						// disabled={bookId === ""}
					/>
				</div>
				{books.length > 0 && (
					<div className="filter">
						<label>Select book: </label>
						<Select
							options={[selectBook, ...books]}
							onChange={getOutlineByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
				)}
			</div>
			<div className="card-container mt-2">
				<div className="p-3">
					{outlines.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No outline to display, click on Add Outline to add new.
						</h4>
					) : (
						outlines.map((outline) => {
							return (
								<OutlineCard
									key={outline.id}
									outline={outline}
									style={{
										backgroundColor: outline.color_code,
										color: outline.foreground_color,
										cursor: "pointer",
									}}
									editOutline={editOutline}
									deleteOutline={deleteOutline}
								/>
							);
						})
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>

			<AddOutlineDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getOutlineByBook={getOutlineByBook}
			/>
			<EditOutlineDrawer
				outline={selectedOutline}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getOutlineByBook={getOutlineByBook}
			/>
		</div>
	);
};

export default Outlines;
