import { Drawer, Button, message } from "antd";
import axios from "axios";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import newBook from "../../assets/images/newBook.png";
import { getColorData } from "../../redux/Slice/ColorSlice";
import Select from "react-select";
import UserGallery from "../gallery/UserGallery";
import TextAreaInput from "../Inputs/TextAreaInput";
import TextInput from "../Inputs/TextInput";

const AddTimelineDrawer = ({ open, close, books, getTimelinesByBook }) => {
	const selectColor = {
		value: "",
		label: "-- Select Color --",
	};
	const selectBook = {
		value: "",
		label: "-- Select Book --",
	};

	const [timelineName, setTimelineName] = useState("");
	const [timelineDescription, setTimelineDescription] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [showGallery, setShowGallery] = useState(false);
	const [loading, setLoading] = useState(false);

	const [colorOptions, setColorOptions] = useState([]);
	const [selectedColor, setSelectedColor] = useState(selectColor);
	const [selectedBook, setSelectedBook] = useState(selectBook);

	const [error, setError] = useState({});

	//dispatching ACTIONS
	const dispatch = useDispatch();
	const { colors } = useSelector((state) => state.colors);

	useEffect(() => {
		dispatch(getColorData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const options = colors.map((color) => {
			return {
				value: color.id,
				label: color.color,
			};
		});

		setColorOptions(() => [selectColor, ...options]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colors]);

	const saveTimeline = (e) => {
		const token = localStorage.getItem("token");
		const plotlineData = {
			book_id: selectedBook.value,
			name: timelineName,
			description: timelineDescription,
			color_id: selectedColor.value,
			image_id: selectedFile?.id ?? null,
		};

		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/timelines`, plotlineData, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then(() => {
				getTimelinesByBook(selectedBook);
				setSelectedBook(selectBook);
				setSelectedColor(selectColor);
				setSelectedFile(null);
				setTimelineName("");
				setTimelineDescription("");
				setError({});
				setLoading(false);
				close();
			})
			.catch((error) => {
				if (error.response.status === 422) {
					setError(error.response.data.errors);
				} else if (error.response.status === 403) {
					message.error(error.response.data.message);
				} else {
					message.error("Something went wrong!");
				}
				setLoading(false);
			});
	};

	return (
		<Drawer
			title={"Add New Timeline"}
			placement="right"
			onClose={close}
			open={open}
			className="p-0 drawer"
			zIndex={0}
			autoFocus={false}
			mask={false}
		>
			<form>
				<label>Artwork</label>
				{showGallery && (
					<UserGallery
						setSelectedFile={(g) => {
							setSelectedFile(g);
						}}
						setShowGallery={setShowGallery}
					/>
				)}
				<div className="artwork mt-3">
					<button
						className="btn-artwork"
						type="button"
						onClick={() => setShowGallery(!showGallery)}
					>
						<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
					</button>
					{selectedFile && (
						<div className="artworkContainer mt-2">
							<button
								onClick={() => {
									setSelectedFile(null);
								}}
								type="button"
								className="btn-remove"
							>
								Remove
							</button>
						</div>
					)}
				</div>
				<div className="mt-3">
					<div className="form-input">
						<label>
							Select Book: <span className="required">*</span>
						</label>
						<Select
							options={[selectBook, ...books]}
							onChange={(e) => {
								setSelectedBook(e);
							}}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
						{error.book_id && <span className="error">{error.book_id}</span>}
					</div>
				</div>
				<div className="mt-3">
					<TextInput
						id="name"
						name="name"
						label="Name"
						maxLength={50}
						required
						value={timelineName}
						onChange={(e) => setTimelineName(e.target.value)}
						error={error.name}
					/>
				</div>

				<div className="mt-3">
					<TextAreaInput
						id="description"
						name="description"
						label="Description"
						maxLength={2048}
						showCount={true}
						value={timelineDescription}
						error={error.description}
						onChange={(e) => setTimelineDescription(e.target.value)}
					/>
				</div>
				<div className="mt-3">
					<div className="form-input">
						<label>Select Color: </label>
						<Select
							options={colorOptions}
							onChange={(e) => {
								setSelectedColor(e);
							}}
							isSearchable={true}
							className="select-book"
							value={selectedColor}
						/>
						{error.color_id && <span className="error">{error.color_id}</span>}
					</div>
				</div>

				<div className="buttons-container mt-5 d-flex space-between">
					<Button
						type="primary"
						className="mr-2"
						loading={loading}
						disabled={loading}
						onClick={saveTimeline}
					>
						Save
					</Button>
					<Button type="ghost" onClick={() => close()}>
						Cancel
					</Button>
				</div>
			</form>
		</Drawer>
	);
};

export default AddTimelineDrawer;
