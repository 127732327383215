import React from "react";
import DeleteIcon from "./DeleteIcon";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const RoundList = ({ rounds, roundIndex, selectRound, deleteRound }) => {
	return (
		<ul className="bs-rounds">
			{rounds.map((round, index) => (
				<li key={round.id}>
					{index === roundIndex && (
						<div className="selected">
							<ArrowRightOutlined />
						</div>
					)}
					<div className="round">
						<Button type="text" onClick={() => selectRound(index)}>
							{round.title} ({round.playtime})
						</Button>
					</div>
					<div className="time-created">{round.created_ago}</div>
					<div className="delete">
						<Button
							type="primary"
							shape="circle"
							danger
							onClick={() => deleteRound(round.id)}
						>
							<DeleteIcon />
						</Button>
					</div>
				</li>
			))}
		</ul>
	);
};

export default RoundList;
