import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router-dom";

import Select from "react-select";
import CustomButton from "../button/Button";
import CreateBookIcon from "../../assets/icons/create-book.svg";
// import FullPageSpinner from "../../components/loader/FullPageSpinner";
import { getBooks, getPlotlines } from "../../helpers/GetList";
// import PackageSubscriptionHook from "../PackageSubscriptionHook";
import PlotlineCard from "./PlotlineCard";
import { HeaderDataContext } from "../../context/HeaderContext";
import { Pagination, message } from "antd";
import axios from "axios";
import AddPlotlineDrawer from "./AddPlotlineDrawer";
import EditPlotlineDrawer from "./EditPlotlineDrawer";

const Plotlines = () => {
	const token = localStorage.getItem("token");
	const header = useContext(HeaderDataContext);
	header.setHeading("Plot Planners");
	const { id } = useParams();
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [plotlines, setPlotlines] = useState([]);
	const selectBook = {
		value: "",
		label: "-- Any Book --",
	};
	const [selectedBook, setSelectedBook] = useState(selectBook);

	const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [selectedPlotline, setSelectedPlotline] = useState(null);
	const [books, setBooks] = useState([]);

	useEffect(() => {
		getBooks(id, setSelectedBook, setBooks);
		getPlotlines(id, 1, setPlotlines, setTotalRecords);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getPlotlinesByBook = (book) => {
		setSelectedBook(book);
		getPlotlines(book.value, 1, setPlotlines, setTotalRecords);
		setCurrentPage(1);
	};

	const handlePageClick = (page) => {
		setCurrentPage(page);
		getPlotlines(selectedBook?.value, page, setPlotlines, setTotalRecords);
	};

	const editPlotline = (id) => {
		axios(`${process.env.REACT_APP_API_URL}/plot-planners/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		})
			.then((response) => {
				setSelectedPlotline(response.data.data);
				setShowEditDrawer(true);
			})
			.catch((error) => console.log(error));
	};

	const deletePlotline = (id) => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/plot-planners/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then(() => {
				getPlotlines(selectedBook?.value, 1, setPlotlines, setTotalRecords);
				setCurrentPage(1);
			})
			.catch(() => message.error("Could not delete timeline."));
	};

	return (
		<div className="main-container">
			{/* <PackageSubscriptionHook
				feature={"plot-planning-feature"}
				packageName={"plot-planning-feature"}
				ref={ref}
				title={"Plotline"}
				checkPackageSubscription={checkSubscription}
			/> */}
			<div className="list-actions">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle={"Add New Plotline"}
						onClick={() => setShowAddNewDrawer(true)}
						icon={<img src={CreateBookIcon} alt="Button Icon" />}
					/>
				</div>
				{books.length > 0 && (
					<div className="filter">
						<label>Select book: </label>
						<Select
							options={[selectBook, ...books]}
							onChange={getPlotlinesByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
				)}
			</div>
			<div className="card-container mt-2">
				<div className="p-3">
					{plotlines.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No Plotline to display, Click on New Plotline Button to add new.
						</h4>
					) : (
						plotlines.map((plotline) => (
							<PlotlineCard
								key={plotline.id}
								style={{
									backgroundColor: plotline.color_code,
									color: plotline.foreground_color,
									cursor: "pointer",
								}}
								plotline={plotline}
								editPlotline={editPlotline}
								deletePlotline={deletePlotline}
							/>
						))
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>
			<AddPlotlineDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getPlotlinesByBook={getPlotlinesByBook}
			/>
			<EditPlotlineDrawer
				plotline={selectedPlotline}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getPlotlinesByBook={getPlotlinesByBook}
			/>
		</div>
	);
};

export default Plotlines;
