import { message } from "antd";
import axios from "axios";
const token = localStorage.getItem("token");

// console.log(token, "====================");
const getOutlines = (bookId, page, setOutlines, setTotalRecords) => {
	axios(
		`${process.env.REACT_APP_API_URL}/outlines-list${
			bookId ? "/" + bookId : ""
		}?page=${page || 1}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		}
	)
		.then((response) => {
			setOutlines(response.data.data);
			setTotalRecords(response.data.meta.total);
		})
		.catch((error) => {
			if (error.response.status === 403) {
				message.error(error.response.data.message);
			} else {
				message.error("Could not fetch Outlines");
			}
		});
};

const getBooks = async (bookId, setSelectedBook, setBooks) => {
	axios(`${process.env.REACT_APP_API_URL}/books-list`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: "application/json",
		},
	})
		.then((response) => {
			const bookOptions = response.data.data.map((book) => {
				if (parseInt(bookId) === book.id) {
					setSelectedBook(() => ({
						value: book.id,
						label: book.book_name,
					}));
				}

				return {
					value: book.id,
					label: book.book_name,
				};
			});
			setBooks(bookOptions);
		})
		.catch((error) => {
			if (error.response.status === 403) {
				message.error(error.response.data.message);
			} else {
				message.error("Could not fetch Books");
			}
		});
};

const getBrainstorms = (bookId, page, setBrainstorms, setTotalRecords) => {
	axios(
		`${process.env.REACT_APP_API_URL}/brainstorms-list${
			bookId ? "/" + bookId : ""
		}?page=${page || 1}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		}
	)
		.then((response) => {
			setBrainstorms(response.data.data);
			setTotalRecords(response.data.meta.total);
		})
		.catch((error) => {
			if (error.response.status === 403) {
				message.error(error.response.data.message);
			} else {
				message.error("Could not fetch Brainstorms");
			}
		});
};

const getPlotlines = (bookId, page, setPlotlines, setTotalRecords) => {
	axios(
		`${process.env.REACT_APP_API_URL}/plot-planners-list${
			bookId ? "/" + bookId : ""
		}?page=${page || 1}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		}
	)
		.then((response) => {
			setPlotlines(response.data.data);
			setTotalRecords(response.data.meta.total);
		})
		.catch((error) => {
			if (error.response.status === 403) {
				message.error(error.response.data.message);
			} else {
				message.error("Could not fetch Plotlines");
			}
		});
};

const getTimelines = (bookId, page, setTimelines, setTotalRecords) => {
	axios(
		`${process.env.REACT_APP_API_URL}/timelines-list${
			bookId ? "/" + bookId : ""
		}?page=${page || 1}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		}
	)
		.then((response) => {
			setTimelines(response.data.data);
			setTotalRecords(response.data.meta.total);
		})
		.catch((error) => {
			if (error.response.status === 403) {
				message.error(error.response.data.message);
			} else {
				message.error("Could not fetch Timelines");
			}
		});
};

export { getBooks, getBrainstorms, getOutlines, getPlotlines, getTimelines };
