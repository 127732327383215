import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router-dom";

import Select from "react-select";
import CustomButton from "../button/Button";
import CreateBookIcon from "../../assets/icons/create-book.svg";
// import FullPageSpinner from "../../components/loader/FullPageSpinner";
import { getBooks, getTimelines } from "../../helpers/GetList";
// import PackageSubscriptionHook from "../PackageSubscriptionHook";
import TimelineCard from "./TimelineCard";
import { HeaderDataContext } from "../../context/HeaderContext";
import { Pagination, message } from "antd";
import axios from "axios";
import AddTimelineDrawer from "./AddTimelineDrawer";
import EditTimelineDrawer from "./EditTimelineDrawer";

const Timelines = () => {
	const token = localStorage.getItem("token");
	const header = useContext(HeaderDataContext);
	header.setHeading("Timelines");
	const { id } = useParams();
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [timelines, setTimelines] = useState([]);
	const selectBook = {
		value: "",
		label: "-- Any Book --",
	};
	const [selectedBook, setSelectedBook] = useState(selectBook);

	const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [selectedTimeline, setSelectedTimeline] = useState(null);
	const [books, setBooks] = useState([]);

	useEffect(() => {
		getBooks(id, setSelectedBook, setBooks);
		getTimelines(id, 1, setTimelines, setTotalRecords);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTimelinesByBook = (book) => {
		setSelectedBook(book);
		getTimelines(book.value, 1, setTimelines, setTotalRecords);
		setCurrentPage(1);
	};

	const handlePageClick = (page) => {
		setCurrentPage(page);
		getTimelines(selectedBook?.value, page, setTimelines, setTotalRecords);
	};

	const editTimeline = (id) => {
		axios(`${process.env.REACT_APP_API_URL}/timelines/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "application/json",
			},
		})
			.then((response) => {
				setSelectedTimeline(response.data.data.timeline);
				setShowEditDrawer(true);
			})
			.catch((error) => console.log(error));
	};

	const deleteTimeline = (id) => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/timelines/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then(() => {
				getTimelines(selectedBook?.value, 1, setTimelines, setTotalRecords);
				setCurrentPage(1);
			})
			.catch(() => message.error("Could not delete timeline."));
	};

	return (
		<div className="main-container">
			{/* <PackageSubscriptionHook
				feature={"plot-planning-feature"}
				packageName={"plot-planning-feature"}
				ref={ref}
				title={"Plotline"}
				checkPackageSubscription={checkSubscription}
			/> */}
			<div className="list-actions">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle={"Add New Timeline"}
						onClick={() => setShowAddNewDrawer(true)}
						icon={<img src={CreateBookIcon} alt="Button Icon" />}
					/>
				</div>
				{books.length > 0 && (
					<div className="filter">
						<label>Select book: </label>
						<Select
							options={[selectBook, ...books]}
							onChange={getTimelinesByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
				)}
			</div>
			<div className="card-container mt-2">
				<div className="p-3">
					{timelines.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No Timeline to display, Click on Add New Timeline button to add
							new.
						</h4>
					) : (
						timelines.map((timeline) => (
							<TimelineCard
								key={timeline.id}
								style={{
									backgroundColor: timeline.color_code,
									color: timeline.foreground_color,
									cursor: "pointer",
								}}
								timeline={timeline}
								editTimeline={editTimeline}
								deleteTimeline={deleteTimeline}
							/>
						))
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>
			<AddTimelineDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getTimelinesByBook={getTimelinesByBook}
			/>
			<EditTimelineDrawer
				timeline={selectedTimeline}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getTimelinesByBook={getTimelinesByBook}
			/>
		</div>
	);
};

export default Timelines;
